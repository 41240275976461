/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import { Injectable } from '@angular/core';
import { isNumeric } from "rxjs/internal-compatibility";
import { AppConstants } from "../shared/app.constants";
import { SelectedFiles } from "../model/SelectedFiles";
import { DocWayDetailsService } from "./docwaydetails.service";

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {
  disableUpload: boolean;
  uploadUrl = '';
  statusUrl = '';
  rejectedFiles: any = [];
  selectedFileReportType: string;
  selectedFileFormat: string;
  selectedFileDealId: string;
  selectedFiles: SelectedFiles[] = [];
  fileSelected = false;

  constructor(private docWayDetailsService: DocWayDetailsService) {
  }

  processSelectedFiles_All(event: any) {
    let selectedFileReportTypeTemp: string;
    let selectedFileDealIdTemp: string;
    this.disableUpload = false;
    this.rejectedFiles = [];

    for (let i = 0; i < event.files.length; i++) {
      //Make sure ReportType is either PCA, ESA, SRA, ZON and check with Docway value if available
      selectedFileReportTypeTemp = event.files[i].name.split(".")[0];
      this.selectedFileFormat = event.files[i].name.split(".")[1].toUpperCase();
      selectedFileReportTypeTemp = selectedFileReportTypeTemp.split("_")[1];
      if (selectedFileReportTypeTemp) {
        if (this.docWayDetailsService.hasReportType) {
          if (selectedFileReportTypeTemp.toUpperCase() == "ZON" && this.docWayDetailsService.mf_doc_type == "Zoning Report")
            this.selectedFileReportType = "ZON";
          else if (selectedFileReportTypeTemp.toUpperCase() == "SRA" && this.docWayDetailsService.mf_doc_type == "Seismic Risk Assessment")
            this.selectedFileReportType = "SRA";
          else if (selectedFileReportTypeTemp.toUpperCase() == "ESA" && this.docWayDetailsService.mf_doc_type == "Environmental Site Assessment")
            this.selectedFileReportType = "ESA";
          else if (selectedFileReportTypeTemp.toUpperCase() == "PCA" && this.docWayDetailsService.mf_doc_type == "Property Condition Assessment")
            this.selectedFileReportType = "PCA";
          else {
            this.selectedFileReportType = "";
            this.rejectedFiles.push(event.files[i].name);
            this.disableUpload = true;
          }
        } else {
          if (selectedFileReportTypeTemp.toUpperCase() == "PCA" ||
            selectedFileReportTypeTemp.toUpperCase() == "ESA" ||
            selectedFileReportTypeTemp.toUpperCase() == "SRA" ||
            selectedFileReportTypeTemp.toUpperCase() == "ZON" ||
            selectedFileReportTypeTemp.toUpperCase() == "BPO" ||
            selectedFileReportTypeTemp.toUpperCase() == "APPR") {
            this.selectedFileReportType = selectedFileReportTypeTemp.toUpperCase();
          } else {
            this.selectedFileReportType = "";
            this.rejectedFiles.push(event.files[i].name);
            this.disableUpload = true;
          }
        }
      } else {
        this.selectedFileReportType = "";
        if (!this.disableUpload)
          this.rejectedFiles.push(event.files[i].name);
        this.disableUpload = true;
      }

      //Check if APPR file is XML
      if (this.selectedFileReportType == "APPR" && this.selectedFileFormat != "XML") {
        this.selectedFileDealId = "";
        if (!this.disableUpload)
          this.rejectedFiles.push(event.files[i].name);
        this.disableUpload = true;
      }

      //Check whether file is TPR or BPO/APPR and validate Deal ID or CRN based on that
      if (this.selectedFileReportType == "BPO" || this.selectedFileReportType == "APPR") {
        //Make sure CRN is numeric and 10 digits long
        selectedFileDealIdTemp = event.files[i].name.split("_")[0];
        var crnRegExp_1 = new RegExp('^[0-9]{10}$');
        var crnRegExp_2 = new RegExp('^[0-9]{10}-[0-9]{3}$');
        if (crnRegExp_1.test(selectedFileDealIdTemp) || crnRegExp_2.test(selectedFileDealIdTemp)) {
          this.selectedFileDealId = selectedFileDealIdTemp;
        } else {
          this.selectedFileDealId = "";
          if (!this.disableUpload)
            this.rejectedFiles.push(event.files[i].name);
          this.disableUpload = true;
        }
      } else {
        //Make sure DealID is numeric and check Deal ID
        selectedFileDealIdTemp = event.files[i].name.split("_")[0];
        if (this.selectedFileReportType == "BPO") {
          if (isNumeric(selectedFileDealIdTemp) && selectedFileDealIdTemp.length == 10) {
            this.selectedFileDealId = selectedFileDealIdTemp;
          }
        } else {
          if (isNumeric(selectedFileDealIdTemp) &&
            (!this.docWayDetailsService.hasDealId)) {
            this.selectedFileDealId = selectedFileDealIdTemp;
          } else if (this.docWayDetailsService.hasDealId &&
            (selectedFileDealIdTemp == this.docWayDetailsService.mf_deal_id) &&
            isNumeric(selectedFileDealIdTemp)) {
            this.selectedFileDealId = selectedFileDealIdTemp;
          } else {
            this.selectedFileDealId = "";
            if (!this.disableUpload)
              this.rejectedFiles.push(event.files[i].name);
            this.disableUpload = true;
          }
        }
      }

      //Add selected files to table
      this.selectedFiles.push({
        fileName: event.files[i].name,
        fileType: event.files[i].name.split(".")[event.files[i].name.split(".").length - 1],
        reportType: this.selectedFileReportType,
        dealId: this.selectedFileDealId
      });

      //Limit number of files uploaded per upload
      if (this.selectedFiles.length > AppConstants.MAX_FILE_UPLOAD_LIMIT)
        this.disableUpload = true;
    }
  }

  processSelectedFiles_ThirdPartyReports_Appraisal(event: any) {
    let selectedFileReportTypeTemp: string;
    let selectedFileDealIdTemp: string;
    this.disableUpload = false;
    this.rejectedFiles = [];

    for (let i = 0; i < event.files.length; i++) {
      //Make sure ReportType is either PCA, ESA, SRA, ZON and check with Docway value if available
      selectedFileReportTypeTemp = event.files[i].name.split(".")[0];
      this.selectedFileFormat = event.files[i].name.split(".")[1].toUpperCase();
      selectedFileReportTypeTemp = selectedFileReportTypeTemp.split("_")[1];
      if (selectedFileReportTypeTemp) {
        if (this.docWayDetailsService.hasReportType) {
          if (selectedFileReportTypeTemp.toUpperCase() == "ZON" && this.docWayDetailsService.mf_doc_type == "Zoning Report")
            this.selectedFileReportType = "ZON";
          else if (selectedFileReportTypeTemp.toUpperCase() == "SRA" && this.docWayDetailsService.mf_doc_type == "Seismic Risk Assessment")
            this.selectedFileReportType = "SRA";
          else if (selectedFileReportTypeTemp.toUpperCase() == "ESA" && this.docWayDetailsService.mf_doc_type == "Environmental Site Assessment")
            this.selectedFileReportType = "ESA";
          else if (selectedFileReportTypeTemp.toUpperCase() == "PCA" && this.docWayDetailsService.mf_doc_type == "Property Condition Assessment")
            this.selectedFileReportType = "PCA";
          else {
            this.selectedFileReportType = "";
            this.rejectedFiles.push(event.files[i].name);
            this.disableUpload = true;
          }
        } else {
          if (selectedFileReportTypeTemp.toUpperCase() == "PCA" ||
            selectedFileReportTypeTemp.toUpperCase() == "ESA" ||
            selectedFileReportTypeTemp.toUpperCase() == "SRA" ||
            selectedFileReportTypeTemp.toUpperCase() == "ZON" ||
            selectedFileReportTypeTemp.toUpperCase() == "APPR") {
            this.selectedFileReportType = selectedFileReportTypeTemp.toUpperCase();
          } else if (selectedFileReportTypeTemp.toUpperCase() == "BPO") {
            this.selectedFileReportType = selectedFileReportTypeTemp.toUpperCase();
            this.rejectedFiles.push(event.files[i].name);
            this.disableUpload = true;
          } else {
            this.selectedFileReportType = "";
            this.rejectedFiles.push(event.files[i].name);
            this.disableUpload = true;
          }
        }
      } else {
        this.selectedFileReportType = "";
        if (!this.disableUpload)
          this.rejectedFiles.push(event.files[i].name);
        this.disableUpload = true;
      }

      //Check if APPR file is XML
      if (this.selectedFileReportType == "APPR" && this.selectedFileFormat != "XML") {
        this.selectedFileDealId = "";
        if (!this.disableUpload)
          this.rejectedFiles.push(event.files[i].name);
        this.disableUpload = true;
      }

      //Check whether file is TPR or APPR and validate Deal ID or CRN based on that
      if (this.selectedFileReportType == "APPR") {
        //Make sure CRN is numeric and 10 digits long
        selectedFileDealIdTemp = event.files[i].name.split("_")[0];
        var crnRegExp_1 = new RegExp('^[0-9]{10}$');
        var crnRegExp_2 = new RegExp('^[0-9]{10}-[0-9]{3}$');
        if (crnRegExp_1.test(selectedFileDealIdTemp) || crnRegExp_2.test(selectedFileDealIdTemp)) {
          this.selectedFileDealId = selectedFileDealIdTemp;
        } else {
          this.selectedFileDealId = "";
          if (!this.disableUpload)
            this.rejectedFiles.push(event.files[i].name);
          this.disableUpload = true;
        }
      } else {
        //Make sure DealID is numeric and check Deal ID
        selectedFileDealIdTemp = event.files[i].name.split("_")[0];
        if (isNumeric(selectedFileDealIdTemp) &&
          (!this.docWayDetailsService.hasDealId)) {
          this.selectedFileDealId = selectedFileDealIdTemp;
        } else if (this.docWayDetailsService.hasDealId &&
          (selectedFileDealIdTemp == this.docWayDetailsService.mf_deal_id) &&
          isNumeric(selectedFileDealIdTemp)) {
          this.selectedFileDealId = selectedFileDealIdTemp;
        } else {
          this.selectedFileDealId = "";
          if (!this.disableUpload)
            this.rejectedFiles.push(event.files[i].name);
          this.disableUpload = true;
        }
      }

      //Add selected files to table
      this.selectedFiles.push({
        fileName: event.files[i].name,
        fileType: event.files[i].name.split(".")[event.files[i].name.split(".").length - 1],
        reportType: this.selectedFileReportType,
        dealId: this.selectedFileDealId
      });

      //Limit number of files uploaded per upload
      if (this.selectedFiles.length > AppConstants.MAX_FILE_UPLOAD_LIMIT)
        this.disableUpload = true;
    }
  }

  processSelectedFiles_BPO(event: any) {
    let selectedFileReportTypeTemp: string;
    let selectedFileDealIdTemp: string;
    this.disableUpload = false;
    this.rejectedFiles = [];

    for (let i = 0; i < event.files.length; i++) {
      //Make sure ReportType is BPO
      selectedFileReportTypeTemp = event.files[i].name.split(".")[0];
      selectedFileReportTypeTemp = selectedFileReportTypeTemp.split("_")[1];
      if (selectedFileReportTypeTemp) {
        if (selectedFileReportTypeTemp.toUpperCase() == "BPO") {
          this.selectedFileReportType = selectedFileReportTypeTemp.toUpperCase();
        } else if (selectedFileReportTypeTemp.toUpperCase() == "PCA" ||
          selectedFileReportTypeTemp.toUpperCase() == "ESA" ||
          selectedFileReportTypeTemp.toUpperCase() == "SRA" ||
          selectedFileReportTypeTemp.toUpperCase() == "ZON") {
          this.selectedFileReportType = "TPR";
          this.rejectedFiles.push(event.files[i].name);
          this.disableUpload = true;
        } else if (selectedFileReportTypeTemp.toUpperCase() == "APPR") {
          this.selectedFileReportType = "APPR";
          this.rejectedFiles.push(event.files[i].name);
          this.disableUpload = true;
        } else {
          this.selectedFileReportType = "";
          this.rejectedFiles.push(event.files[i].name);
          this.disableUpload = true;
        }
      } else {
        this.selectedFileReportType = "";
        if (!this.disableUpload)
          this.rejectedFiles.push(event.files[i].name);
        this.disableUpload = true;
      }

      //Make sure CRN is numeric and 10 digits long
      selectedFileDealIdTemp = event.files[i].name.split("_")[0];
      var crnRegExp_1 = new RegExp('^[0-9]{10}$');
      var crnRegExp_2 = new RegExp('^[0-9]{10}-[0-9]{3}$');
      if (crnRegExp_1.test(selectedFileDealIdTemp) || crnRegExp_2.test(selectedFileDealIdTemp)) {
        this.selectedFileDealId = selectedFileDealIdTemp;
      } else {
        this.selectedFileDealId = "";
        if (!this.disableUpload)
          this.rejectedFiles.push(event.files[i].name);
        this.disableUpload = true;
      }

      //Add selected files to table
      this.selectedFiles.push({
        fileName: event.files[i].name,
        fileType: event.files[i].name.split(".")[event.files[i].name.split(".").length - 1],
        reportType: this.selectedFileReportType,
        dealId: this.selectedFileDealId
      });

      //Limit number of files uploaded per upload
      if (this.selectedFiles.length > AppConstants.MAX_FILE_UPLOAD_LIMIT)
        this.disableUpload = true;
    }
  }

  onClearFile() {
    this.fileSelected = false;
    this.selectedFiles = [];
    this.disableUpload = false;
    this.rejectedFiles = [];
  }
}
